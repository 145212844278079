import React, { Component } from "react";
import Layout from "../layout";
import Gst from "../images/gst.jpg";
import Hospital from "../images/hospital.jpg";
import SuperMarket from "../images/supermarket.jpg";


class ProductsPage extends Component {
  render() {
    return (
      <Layout>
     {/*     <div className="bg-white">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <a key={product.id} href={product.href} className="group">
              <div className="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="w-full h-full object-center object-cover group-hover:opacity-75"
                />
              </div>
              <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3>
              <p className="mt-1 text-lg font-medium text-gray-900">{product.price}</p>
            </a>
          ))}
        </div>
      </div>
    </div>

*/}

         
        <div class="row bg-slate-100">
            <div className="container with-padding">
              <h4 className="product_heading">Products</h4>
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 py-4 ">
                    <div>
                        <img
                          className="h-ful w-full object-cover rounded-xl pb-4"
                          src={Gst} 
                          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                        /> 
                        <h4 class="text-2xl font-extrabold text-slate-900 tracking-tight sm:text-3xl pb-4">GST Billing Software</h4>
                        <p class="service_p_tag">
                        Wecare Technologies is the leading GST invoice billing software providers in Madurai. Customer is our most valuable asset, so we always come up with customized..
                        </p>
                    </div>
                    <div>
                        <img
                          className="h-ful w-full object-cover rounded-xl pb-4"
                          src={Hospital} 
                          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                        />
                        <h4 class="text-2xl font-extrabold text-slate-900 tracking-tight sm:text-3xl pb-4">Hospital Managment Software</h4>
                        <p class="service_p_tag">
                        Wecare Technologies is the leading GST invoice billing software providers in Madurai. Customer is our most valuable asset, so we always come up with customized..
                        </p> 
                    </div>
                    <div>
                        <img
                          className="h-ful w-full object-cover rounded-xl pb-4"
                          src={SuperMarket} 
                          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
                        />
                        <h4 class="text-2xl font-extrabold text-slate-900 tracking-tight sm:text-3xl pb-4">Supermarket Billing Software</h4>
                        <p class="service_p_tag">
                        Wecare Technologies is having more than 10 years of retail industry billing software development experience such as supermarkets, grocery stores...
                        </p> 
                    </div>  
                </div>
            </div>
        </div>    
    
              </Layout>
    )
  }
}

export default ProductsPage;
